import React, { useState } from "react";
import "./Footer.scss";
import { images } from "../../constants";
// import { client } from "../../client";
import { AppWrap, MotionWrap } from "../../wrapper";
import emailjs from "@emailjs/browser";
import sendEmail from "../../components/MailsendApi";

const Footer = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { name, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);

    if (name && email && message) {
      const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
      const templateId = process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID;
      const publicKey = process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY;
      const templateParams = {
        name,
        email,
        message,
      };

      sendEmail(name, email, message)
      setLoading(false);
      setIsFormSubmitted(true);
    } else {
      alert("Please fill in all fields");
    }

    // NOTE below code is for sanity contact form
    // const contact = {
    //   _type: "contact",
    //   name: name,
    //   email: email,
    //   message: message,
    // };

    // client.create(contact).then(() => {
    //   setLoading(false);
    //   setIsFormSubmitted(true);
    // });
  };

  return (
    <>
      <h2 className="head-text ">
        Take a <span>coffee</span> & <span>chat</span> with Us
      </h2>

      <div className="app__footer-cards">
        <div className="app__footer-card">
          <img src={images.email} alt="email" loading="lazy" />
          <a href="mailto:contact@web3crafters.com" className="p-text">
            <div className="contact-item">
                <h3>Contact Info</h3>
                <p>krishnakishorayarkad@gmail.com</p>
                <p className="adressWidth">
                  <span>
                    <i className="fa fa-map-marker"></i> Address : 
                  </span>
                  <span> Web3Crafters, 52 Porrnsashree , 11th Main , Mathru Shree Road , Akshaya Nagara west, Banglore, Karnatka, 671543, INDIA</span>
                </p>
              </div>
            <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone : 
                  </span>{" "}
                  <span> +91 7259876200</span>
                </p>
              </div>
          </a>
        </div>
      </div>

      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input
              type="text"
              className="p-text"
              placeholder="Your Name"
              name="name"
              value={name}
              onChange={handleChangeInput}
            />
          </div>
          <div className="app__flex">
            <input
              type="email"
              className="p-text"
              placeholder="Your Email"
              name="email"
              value={email}
              onChange={handleChangeInput}
            />
          </div>
          <div>
            <textarea
              name="message"
              placeholder="Your Message"
              value={message}
              className="p-text"
              onChange={handleChangeInput}
            />
          </div>

          <button type="button" className="p-text" onClick={handleSubmit}>
            {loading ? "Sending" : "Send Message"}
          </button>
        </div>
      ) : (
        <div>
          <h3 className="head-text">Thank you for getting in touch</h3>
        </div>
      )}
    </>
  );
};
export default AppWrap(
  MotionWrap(Footer, "app__footer"),
  "contact",
  "app__whitebg"
);
